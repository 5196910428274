<template>
  <div class="product-container">
    <header class="hidden sm:flex justify-start">
      <div>
        <slot name="breadcrumbs" />
      </div>
    </header>

    <div class="product-detail-wrapper">

      <!-- Left Side -->
      <div class="left-content">
        <slot name="slider" />
      </div>

      <!-- Right Side -->
      <div class="right-content">
        <div class="right-content--wrapper">
          <div class="">
            <slot name="brand" />
            <slot name="title" />
            <slot name="modelSku" />
            <slot name="reviewsInfo" />
          </div>

          <div class="space-y-4">
            <!-- Toptan Fiyatı -->
            <slot name="wholesalePrice" />

            <!-- B2C Fiyatı -->
            <slot name="productPrice" />

            <!-- Kampanya Bilgisi -->
            <slot name="discountLabel" />

            <!-- Son Kalan Ürünler -->
            <slot name="productCriticalQuantity" />
          </div>

          <!-- Renk Seçenekleri -->
          <slot name="colorOptions" />

          <!-- Beden Seçenekleri -->
          <slot name="sizeOptions" />

          <!-- Taksit Bilgisi - X TL'den Başlayan Fiyatlarla -->
          <slot name="installmentInfo" />

          <!-- Ürün Özellikleri -->
          <slot name="productAttributes"></slot>

          <div class="mt-6">
            <!-- Birim -->
            <slot name="unitInfo" />

            <!-- Sepete Ekle, Favorilere Ekle vb -->
            <slot name="shopButtons" />
          </div>

          <!-- Öne Çıkan Site Özellikleri -->
          <slot name="highlights" />

          <!-- Manken Bilgisi -->
          <div v-if="selectedVariant?.model && selectedVariant.model?.length > 0">
            <h3 class="font-bold text-base mb-1">{{ $t('listing.feature-disclosure-titles.model-info') }}</h3>
            <FeaturesModelInfo :models="selectedVariant.model" class="text-primary" />
          </div>

          <!-- Ürün Açıklaması -->
          <div v-if="selectedVariant?.description" v-html="selectedVariant.description"></div>
          <p v-else>{{ $t('listing.no-product-description') }}</p>

        </div>
      </div>
    </div>

    <!-- Benzer Ürünler -->
    <slot name="relatedProducts" />
  </div>
</template>

<script setup>
import "assets/css/modules/product-detail-airlife.css"

const {selectedVariant} = await useProduct();

</script>
